import axios from "axios";
import API from "@/services/Api";

const state = {};

const getters = {};

const mutations = {};

const actions = {

};

export default {
  state,
  mutations,
  actions,
  getters
};
