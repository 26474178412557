import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';
import TicketDetailLayout from '@/layout/dashboard/TicketDetailLayout.vue';

import AuthLayout from '@/layout/auth/AuthLayout.vue';
// GeneralViews
import NotFound from '@/pages/NotFoundPage.vue';

// Admin pages
const routes = [{
    path: '/',
    component: DashboardLayout,
    children: [{
        path: '',
        meta: {
          title: "Dashboard",
          requiresAuth: true
        },
        name: 'dashboard',
        component: () => import('@/pages/Dashboard.vue')
      },
      {
        path: 'profile',
        meta: {
          title: "Perfil",
          requiresAuth: true
        },
        name: 'profile',
        component: () => import('@/pages/Profile.vue')
      },
      {
        path: 'mis-tickets',
        name: 'mytickets',
        meta: {
          title: "Mis tickets",
          requiresAuth: true
        },
        component: () => import('@/pages/MyTickets.vue')
      },
      {
        path: 'tickets',
        meta: {
          title: "Tickets",
          requiresAuth: true
        },
        name: 'tickets',
        component: () => import('@/pages/Tickets.vue')
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          title: "Configuraciones",
          requiresAuth: true
        },
        component: () => import('@/pages/SettingsPanel.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        meta: {
          title: "Reportes",
          requiresAuth: true
        },
        component: () => import('@/pages/Reports.vue')
      },
    ]
  },
  {
    path: '/',
    component: TicketDetailLayout,
    children: [{
        path: 'ticket/:id',
        meta: {
          title: "Detalle ticket",
          requiresAuth: true
        },
        name: 'ticket-detail',
        props: true,
        component: () => import('@/pages/Ticket.vue')
      },

    ]
  },
  {
    path: '/settings',
    component: DashboardLayout,
    children: [{
      path: 'areas',
      meta: {
        title: "Areas",
        requiresAuth: true
      },
      name: 'departments',
      component: () => import('@/pages/Departments.vue')
    }, {

      path: 'tipo-plantilla-email',
      meta: {
        title: "Tipo plantilla Email",
        requiresAuth: true
      },
      name: 'mailTemplateTypes',
      component: () => import('@/pages/MailTemplateTypes.vue')
    }, {
      path: 'plantilla-email',
      meta: {
        title: "Plantilla Email",
        requiresAuth: true
      },
      name: 'mailTemplates',
      component: () => import('@/pages/MailTemplates.vue')
    }, {
      path: 'proyectos',
      meta: {
        title: "Proyectos",
        requiresAuth: true
      },
      name: 'projects',
      component: () => import('@/pages/Projects.vue')
    }, {
      path: 'usuarios',
      meta: {
        title: "Usuarios",
        requiresAuth: true
      },
      name: 'users',
      component: () => import('@/pages/Users.vue')
    }, {
      path: 'roles',
      meta: {
        title: "Roles",
        requiresAuth: true,
        rule: {
          id: 1
        }
      },
      name: 'roles',
      props: true,
      component: () => import('@/pages/Roles.vue')
    }, {
      path: 'empresas',
      meta: {
        title: "empresas",
        requiresAuth: true
      },
      name: 'companies',
      component: () => import('@/pages/Companies.vue')
    }, {
      path: 'empresa/:id',
      meta: {
        title: "Detalle empresa",
        requiresAuth: true
      },
      name: 'company-detail',
      props: true,
      component: () => import('@/pages/Company.vue')
    }]
  },
  {
    path: '/',
    component: AuthLayout,
    children: [{
      path: 'login',
      name: 'login',
      component: () => import('@/pages/auth/Login.vue')
    }, {
      path: 'register',
      name: 'register',
      component: () => import('@/pages/auth/Register.vue')
    }, {
      path: 'reset-password',
      name: 'resetpassword',
      component: () => import('@/pages/auth/ResetPassword.vue')
    }, {
      path: 'complete-reset-password',
      name: 'resetpasswordconfirm',
      component: () => import('@/pages/auth/ResetPasswordConfirm.vue')
    }, {
      path: 'complete-registration',
      name: 'completeregistration',
      component: () => import('@/pages/auth/CompleteRegistration.vue')
    }]
  },
  {
    path: '*',
    component: NotFound
  }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/




export default routes;
