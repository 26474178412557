<template>
  <div class="wrapper ticket-detail-wrapper">
    <side-bar class="side-bar-x">
      <template slot="links">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />

        <sidebar-link
          to="/tickets"
          :name="$t('sidebar.allTickets')"
          icon="tim-icons icon-support-17"
        />

        <sidebar-link to="/mis-tickets" :name="$t('sidebar.myTickets')" icon="tim-icons icon-tag" />
        <sidebar-link
          to="/settings"
          :name="$t('sidebar.settingsPanel')"
          icon="tim-icons icon-settings-gear-63"
        />
      </template>
    </side-bar>
    <div class="main-panel" data="blue">
      <top-navbar class="esaia"></top-navbar>
      <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  mounted() {
    this.toggleSidebar();
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>

<style lang="scss">
.ticket-detail-wrapper {
  .sidebar {
    display: none !important;
  }
  .main-panel {
    width: 100%;
    .content {
      padding-left: 30px;
    }
  }
  .navbar-brand {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 991px) {
  .ticket-detail-wrapper {
    .sidebar {
      display: block !important;
    }
  }
}
</style>
