<template>
  <div class="wrapper">
    <side-bar>
      <p class="text-center mt-3" v-if="loadingMenu">
        <img src="/gpassist/img/loader.gif" alt="loader" width="25" />
      </p>
      <template slot="links" v-else>
        <sidebar-link
          v-if="menus && menus.length"
          v-for="(item, index) in menus.filter(x=>x.level == 1 && x.canRead == true)"
          :key="index"
          :to="{name: item.pathName}"
          :name="item.name"
          :icon="item.icon"
        />
      </template>
    </side-bar>
    <div class="main-panel" data="blue">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {
      menu: []
    };
  },
  computed: {
    ...mapState({
      menus: state => state.menus,
      loadingMenu: state => state.loadingMenu
    })
  },
  mounted() {
    //console.log("mounted", new Date());
    if (localStorage.getItem("access_token")) {
      //this.$store.dispatch("getMenus");
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
