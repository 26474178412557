 import API from "@/services/Api";

 const state = {
   tickets: [],
   ticket: {},
   assignedUsers: [],
   options: [],
 };

 const getters = {};

 const mutations = {
   SET_TICKETS(state, payload) {
     state.tickets = payload;
   },
   ADD_TICKET(state, payload) {
     state.tickets.push(payload);
   },
   SET_TICKET(state, payload) {
     state.ticket = payload;
   },
   SET_OPTIONS(state, payload) {
     state.options = payload
   },
   SET_VALUES(state, payload) {
     state.ticket.assignedUsers = payload,
       state.assignedUsers = payload
   },
 };

 const actions = {
   getTickets({
     commit
   }) {
     return new Promise((resolve, reject) => {
       API.get("Ticket/GetAllAsync")
         .then(response => {
           commit("SET_TICKETS", response.data.result);
           resolve(response);
         })
         .catch(error => {
           reject(error.response);
         });
     });
   },
   loadOptions({
     commit
   }) {

     API.get("User/GetAllAsync")
       .then(response => {
         //console.log("SET_OPTIONS", response.data.result);
         commit("SET_OPTIONS", response.data.result);
       })
       .catch(error => {});

   },
   updateValueAction({
     commit
   }, payload) {
     //console.log('updateValueAction: ', payload)
     commit('SET_VALUES', payload)
   },
 };

 export default {
   state,
   mutations,
   actions,
   getters
 };
