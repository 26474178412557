<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright w-100" :class="className">
        © {{year}} made with
        <i class="tim-icons icon-heart-2"></i>
        by
        <a href="http://www.grupoperti.com.mx" target="_blank" rel="noopener">Grupo PerTI</a> for a better web.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    className: {
      type: String,
      default: " text-right "
    }
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
</style>
