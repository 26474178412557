import VueRouter from 'vue-router';
import routes from './routes';

// configure router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes, // short for routes: routes
  linkExactActiveClass: 'active',
  scrollBehavior: to => {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token') == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      next(vm => {
        const menus = vm.$store.state.menus;
        // console.log("menus1: ", menus)
      })
    }
  } else {
    next(vm => {
      const menus = vm.$store.state.menus;
      // console.log("menus2: ", menus)
    })
  }

})
export default router;
