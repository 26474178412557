import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import statusType from './modules/statusType';
import ticket from './modules/ticket';
import user from './modules/user';
import API from "@/services/Api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menus: [],
    loadingMenu: false,
    headerWelcome: "",
    roleName: "",
    dashboardRule: ""
  },
  mutations: {
    SET_MENUS(state, payload) {
      state.menus = payload;
    },
    SET_LOADING(state, payload) {
      state.loadingMenu = payload;
    },
    SET_WELCOME(state, payload) {
      state.headerWelcome = payload;
    },
    SET_ROLENAME(state, payload) {
      state.roleName = payload;
    },
    SET_DASHBOARD_RULE(state, payload) {
      state.dashboardRule = payload;
    },
  },
  actions: {
    getMenus({
      commit
    }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);
        API.get("Account/GetMenus")
          .then(response => {
            commit("SET_LOADING", false);
            if (response && response.data && response.data.resultCode == 1) {
              //console.log("SET_MENUS", response.data.result);
              const menus = response.data.result;
              commit("SET_MENUS", menus);
              commit("SET_DASHBOARD_RULE", menus.find(x => x.name == "Dashboard"));
            }
            resolve(response);
          })
          .catch(error => {
            commit("SET_LOADING", false);
            reject(error.response);
          });
      });
    },
    getUserData({
      commit
    }) {
      return new Promise((resolve, reject) => {
        API.get("User/GetUserById")
          .then(response => {
            if (response && response.data && response.data.resultCode == 1) {
              const userData = response.data.result;
              commit("SET_WELCOME", `${userData.firstName} ${userData.lastName} (${userData.roleName})`);
              commit("SET_ROLENAME", `${userData.roleName}`);
              localStorage.setItem("user_data", JSON.stringify(userData));
            }
            resolve(response);
          })
          .catch(error => {
            reject(error.response);
          });
      });
    },
  },
  modules: {
    auth,
    statusType,
    ticket,
    user
  },
});
