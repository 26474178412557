<template>
  <div class="contact-us full-screen h-100">
    <div class="text-center not-found">
      <p class="display-1">
        <img :src="'/gpassist/img/404.png'" alt="404" width="200" />
      </p>
      <p class="title">Oops! Parece que esta página no existe.</p>
      <p>
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
        <span class="px-2">|</span>
        <router-link :to="{name:'login'}">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import ContentFooter from "@/layout/dashboard/ContentFooter.vue";
export default {
  components: {
    ContentFooter
  }
};
</script>
<style lang="scss">
.not-found {
  position: absolute;
  height: 89%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  padding: 24px 0 !important;
}
</style>

