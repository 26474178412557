import axios from "axios";
import API from "@/services/Api";

const state = {
  users: []
};

const getters = {
  getMovieById: (state) => (id) => {
    return state.users.filter(user => user.id === id)
  },
  getUsers: (state) => {
    return state.users
  }
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
};

const actions = {

};

export default {
  state,
  mutations,
  actions,
  getters
};
