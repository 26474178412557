import axios from "axios";
import Global from './Global';
// You can use your own logic to set your local or production domain
// The base URL is empty this time due we are using the jsonplaceholder API
const baseURL = Global.getBaseURL() + '/api';
// const baseURL = 'https://ticketgp.devgp.xyz/api';
// const baseURL = 'https://localhost:5001/api';
console.log(baseURL);
const API = axios.create({
  baseURL,
  withCredentials: false,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
  }
});

let token = localStorage.getItem('access_token')
//console.log("token: ", token);
//token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYXVyaWNpby5zaWx2ZXN0cmVAZ3J1cG9wZXJ0aS5jb20ubXgiLCJqdGkiOiI1NWVjZmViYi03MDJiLTQ3YWEtODI0Yy0wZmY5NWM5MDg0MTMiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJBZG1pbmlzdHJhZG9yIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZW1haWxhZGRyZXNzIjoibWF1cmljaW8uc2lsdmVzdHJlQGdydXBvcGVydGkuY29tLm14IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZ2l2ZW5uYW1lIjoibWF1cmljaW8uc2lsdmVzdHJlQGdydXBvcGVydGkuY29tLm14IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6Ik1hdXJpY2lvIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvc3VybmFtZSI6IlNpbHZlc3RyZSIsIkNvbXBhbnlOYW1lIjoiIiwiQ29tcGFueUlkIjoiIiwiZXhwIjoxNTk2NzI5NDY4LCJpc3MiOiJHcnVwbyBQZXJUSSIsImF1ZCI6IkdydXBvIFBlclRJIn0.NiSfkDJRs3fdH6mD43_Pq_zFbKm10IuFnF-KuFEzJoE"
if (token) {
  API.defaults.headers.common['Authorization'] = `Bearer ${
    token //JSON.parse(token)
  }`

}

export default API;
