export const currency = value => {
  let amount = parseFloat(value);
  amount = isNaN(amount) ? 0 : parseFloat(value).toFixed(2);
  return "$" + amount;
};
// export const date = value => {
//   const date = new Date(value);
//   const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
//   const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
//   const yyyy = date.getFullYear();

//   const HH = date.getHours();
//   const mi = date.getMinutes();
//   const ss = date.getSeconds();
//   return yyyy + "/" + MM + "/" + dd + " " + HH + ":" + mi + ":" + ss;
// };

export const number = value => {
  if (!window.Intl) return value;
  return new Intl.NumberFormat().format(value);
};

export const date = value => {
  if (!window.Intl) return value;
  // convert to date
  if (!(value instanceof Date)) {
    let orig = value;
    value = new Date(value);
    if (value == "Invalid Date") return orig;
  }

  return new Intl.DateTimeFormat().format(value);
};


export const datetime = value => {
  if (!window.Intl) return value;
  // convert to date
  if (!(value instanceof Date)) {
    let orig = value;
    value = new Date(value);
    if (value == "Invalid Date") return orig;
  }

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  };

  return new Intl.DateTimeFormat('default', options).format(value);
};
