import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import App from './App';
import Axios from "axios";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from './router';
import store from '@/store';

import BlackDashboard from './plugins/blackDashboard';
import i18n from './i18n';
import './registerServiceWorker';
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

// VeeValidate
const dictionary = {
  en: {
    messages: {
      email: function () {
        return "I really dont like your email"
      }
    }
  }
};
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});

// VueSwal
import VueSwal from "vue-swal";
Vue.use(VueSwal);

// nprogress
import "nprogress/nprogress.css";

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

//filters
import {
  currency,
  date,
  number,
  datetime
} from "@/filters";
Vue.filter("date", date);
Vue.filter("datetime", datetime);


Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
