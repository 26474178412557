<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
    if (localStorage.getItem("access_token")) {
      this.$store.dispatch("getMenus");
    }
  },
  methods: {
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    }
  }
};
</script>
 
