import axios from "axios";
import API from "@/services/Api";

const state = {
  users: [],
  user: {},
  userData: null,
  authenticated: false
};

const getters = {
  isLoggedIn(state) {
    return state.authenticated;
  },
  getUser() {
    var userData = null; //JSON.parse(localStorage.getItem("userData"));
    return userData ? userData.user : "";
  },
  loggedIn(state) {
    return !!state.userData;
  }
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload;
    localStorage.setItem("access_token", payload.result.token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      payload.result.token
    }`;
    API.defaults.headers.common["Authorization"] = `Bearer ${
      payload.result.token
    }`;
  },
  REMOVE_USER_DATA(state) {
    state.userData = null;
    state.authenticated = false;
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_data");
    delete axios.defaults.headers.common["Authorization"];
    delete API.defaults.headers.common["Authorization"];
    //console.log("data removed")
    //location.reload();
  },
  SET_USER_AUTHENTICATED(state) {
    state.authenticated = true;
  }
};

const actions = {

  fetchUsers({
    commit
  }) {
    return new Promise((resolve, reject) => {
      API.get("person")
        .then(response => {
          commit("SET_USERS", response.data);
          //console.log(response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  register({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      API.post("Account/Register", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  login({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      API.post("Account/Login", payload)
        .then(response => {
          if (response && response.data && (response.data.resultCode == 1 || response.data.resultCode == 0)) {
            commit("SET_USER_DATA", response.data);
            commit("SET_USER_AUTHENTICATED");
          }
          resolve(response);
        })
        .catch(error => {
          commit("REMOVE_USER_DATA");
          reject(error.response);
        });
    });
  },
  refreshToken({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/token/refresh", payload)
        .then(response => {
          const userData = response.data;
          userData.user = {
            userName: payload.email
          };
          commit("SET_USER_DATA", userData);
          commit("SET_USER_AUTHENTICATED");

          resolve(response);
        })
        .catch(error => {
          commit("REMOVE_USER_DATA");
          reject(error.response);
        });
    });
  },
  logout({
    commit
  }) {
    return new Promise(resolve => {
      //console.log("TO LOGOUT")
      commit("REMOVE_USER_DATA");
      resolve();
    });
  },
  resetPassword({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      API.post("Account/ResetPassword?email=" + payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  },
  resetPasswordConfirm({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      API.post("Account/ResetPasswordConfirm", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error.response);
        });
    });
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
