let global = {
  noDataFound: "No hay registro",
  deleteSuccessMessage: "Eliminado con éxito",
  deleteConfirmMessage: "¿Esta seguro de eliminar ese registro?",
  insertSuccessMessage: "El registro se guardo con éxito",
  insertfailedMessage: "no se pudo crear ese registro",
  fatalErrorMessage: "Error: No se puede procesar esta información.",
  concelConfirmMessage: "¿Esta seguro de cancelar ese registro?",
}

//base URL

global.getBaseURL = () => {  
  let baseURL = `http://localhost:32769`; 
  baseURL = ''
  return baseURL
};

export default global;
