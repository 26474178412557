<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>

        <span class="navbar-brand pointer">{{routeName}}</span>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <li class="nav-item d-flex align-items-center pr-0">
              <div v-if="headerWelcome">
                <span>{{headerWelcome}}</span>
              </div>
              <div v-else>
                <span>{{storageHeaderWelcome}}</span>
              </div>
            </li>

            <base-dropdown
              tag="li"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="javascript:void(0)"
                class="dropdown-toggle nav-link pl-0"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div class="photo d-flex align-items-center justify-content-center">
                  <!-- <span>{{tagName}}</span> -->
                  <i class="far fa-user"></i>
                  <img :src="'/gpassist/img/anime3.png'" hidden />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Log out</p>
              </a>
              <li class="nav-link">
                <router-link class="nav-item dropdown-item" :to="{name: 'profile'}">Perfil</router-link>
              </li>

              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <button class="nav-item dropdown-item pointer" @click="logout">Log out</button>
              </li>
            </base-dropdown>
            <li class="nav-item d-flex align-items-center">
              <router-link :to="{name: 'settings'}" class="d-flex">
                <p class="mb-0">
                  <i class="tim-icons icon-settings-gear-63 mr-2"></i>
                  <span class="d-lg-none">Configuraciones</span>
                </p>
              </router-link>
            </li>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";

export default {
  components: {
    CollapseTransition,
    Modal
  },
  computed: {
    routeName() {
      const { meta } = this.$route;
      //console.log(this.$route);
      return this.capitalizeFirstLetter(meta && meta.title ? meta.title : "");
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    headerWelcome() {
      return this.$store.state.headerWelcome;
    },
    storageHeaderWelcome() {
      return this.user
        ? `${this.user.firstName} ${this.user.lastName} (${this.user.roleName})`
        : "";
    },
    tagName() {
      const fn =
        this.user && this.user.firstName
          ? this.user.firstName.substr(0, 1)
          : "";
      const ln =
        this.user && this.user.lastName ? this.user.lastName.substr(0, 1) : "";
      return `${fn}${ln}`;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      user: null
    };
  },
  mounted() {
    this.getUserData();
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push({ name: "login" }));
    },
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
      }
    }
  }
};
</script>
<style>
</style>
